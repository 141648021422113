// Import Swiper styles
import 'swiper/css'

import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { capitalize,
  filter,
  find,
  get,
  isNull,
  join,
  map,
  reduce,
  round,
  some,
  sumBy,
  toNumber } from 'lodash-es'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { Swiper, SwiperSlide } from 'swiper/react'
import { twMerge } from 'tailwind-merge'
import { v4 } from 'uuid'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import ElapsedTimerWithProgress from '@/Components/common/input/ElapsedTimerWithProgress'
import HorizontalProgressBar from '@/Components/common/progressbars/HorizontalProgressBar'
import LeafletMap from '@/Components/LeafletMap'
import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'
import { modalState } from '@/Config/Atoms/General'
import { getByPath } from '@/Utilities/Accessors/Helpers'
import { isQuantityBasis, isTimeBasis } from '@/Utilities/Basis'
import { publish } from '@/Utilities/Events'
import { formatDecimals } from '@/Utilities/Internationalization'
import { addBlocks } from '@/Utilities/Map/Map'
import { statusColor } from '@/Utilities/StatusColor'
import useAuth from '@/Utilities/useAuth'
import usePendingChange from '@/Utilities/usePendingChange'

export default function ProgramSetTabSwiper({
  activeIndex,
  onIndexChange = () => {},
  minTabHeight = 280,
  programSet,
  status,
}) {
  const auth = useAuth()
  const {
    hasPendingChange,
    getPendingChangeDisabledReason,
  } = usePendingChange()

  const [swiperInstance, setSwiperInstance] = useState(null)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [mapVisible, setMapVisible] = useState(false)
  const [mapBlocksResult, setMapBlocksResult] = useState(null)
  const setModal = useSetRecoilState(modalState)

  // Map
  const mapInstance = useRef(null)
  const mapId = useRef(v4())

  const mapInit = (map) => {
    if (!mapLoaded) {
      mapInstance.current = map
      setMapLoaded(true)
    }
  }

  // Handle map blocks
  const mapBlocks = useMemo(() => {
    let color = get(statusColor(programSet.operationalStatuses), 'pathColor', '#1570ef')

    // Gray to primary color
    if (color === '#647087') {
      color = '#1570ef'
    }

    return reduce(programSet.blocks, (accumulatedBlocks, block) => {
      const {
        id,
        geoJson,
      } = block

      const {
        geoJson: {
          properties: {
            ...otherProperties
          },
        },
      } = block

      return [...accumulatedBlocks, {
        ...geoJson,
        properties: {
          ...otherProperties,
          id,
          color,
        },
      }]
    }, [])
  }, [
    programSet.blocks,
    status,
    mapInstance,
  ])

  const canEditProgramSet = useMemo(() => {
    return status?.key !== 'running' && !hasPendingChange(programSet)
  }, [status])

  const runningProgramNames = useMemo(() => {
    const runningPrograms = filter(programSet.programs, (program) => {
      return some(program.operationalStatuses, { key: 'running' })
    })

    return join(map(runningPrograms, (program) => {
      return program.name
    }), ', ')
  }, [programSet.programs, status])

  const quantityProgress = useMemo(() => {
    const returnValue = {
      totalQuantity: 0,
      progressPercentage: 0,
      accumulated: 0,
      unitOfMeasurement: null,
    }

    if (!isQuantityBasis(programSet)) {
      return returnValue
    }

    const stat = find(programSet.stats, { key: 'remainingQuantity' })
    const totalQuantity = sumBy(getByPath(programSet, 'programs.*'), 'waterQuantity')
    const accumulated = totalQuantity - get(stat, 'value', 0)

    return {
      totalQuantity,
      accumulated,
      progressPercentage: round(accumulated / totalQuantity * 100),
      unitOfMeasurement: get(programSet, 'unitOfMeasurement', null),
    }
  }, [programSet])

  useEffect(() => {
    if (!mapLoaded || !mapBlocks.length) {
      return
    }

    const blocksResult = addBlocks({
      blocks: mapBlocks,
      drawingLayer: mapInstance.current.groups.drawings,
      clearLayer: true,
      withBounds: true,
    })

    setMapBlocksResult(blocksResult)
  }, [mapBlocks, mapLoaded])

  useEffect(() => {
    if (mapBlocksResult?.bounds.isValid()) {
      mapInstance.current?.map.fitBounds(mapBlocksResult.bounds)
    } else {
      const lat = get(programSet?.site, 'lat', 0)
      const lng = get(programSet?.site, 'lng', 0)

      mapInstance.current?.map.setView([lat, lng], 16)
    }
  }, [mapVisible, mapInstance])

  useEffect(() => {
    if (!isNull(swiperInstance) && activeIndex >= 0 && activeIndex !== swiperInstance.realIndex) {
      swiperInstance.slideTo(activeIndex)
    }
  }, [activeIndex, swiperInstance])

  return (
    <Swiper
      className="relative flex w-full"
      spaceBetween={0}
      slidesPerView={1}
      grabCursor={true}
      autoHeight={true}
      onSwiper={setSwiperInstance}
      onRealIndexChange={(swiper) => {
        onIndexChange(swiper.realIndex)
      }}
      onSlideChangeTransitionEnd={(swiper) => {
        // Update the map visibility state to perform actions after the slide transition is complete,
        // such as fitting bounds, etc.
        if (swiper.realIndex === 1) {
          setMapVisible(swiper.realIndex === 1)
        } else {
          setMapVisible(false)
          setMapLoaded(false)
          mapInstance.current = null
        }
      }}
    >
      <SwiperSlide className="relative" style={{ minHeight: minTabHeight }}>
        <PermissionGuard permission="update-program" auth={auth}>
          <div className="absolute top-4 right-6 inline-block">
            <Tippy
              theme="light"
              placement="top"
              delay={200}
              content={canEditProgramSet
                ? 'Edit program set'
                : (
                  hasPendingChange(programSet)
                    ? getPendingChangeDisabledReason(programSet)
                    : `You cannot edit or delete this as the following programs are running: ${runningProgramNames}`
                )
              }
            >
              <i
                className={
                  twMerge(
                    'fa-solid fa-pen-to-square cursor-pointer text-slate-300',
                    classNames({ 'text-slate-500 hover:text-primary': canEditProgramSet }),
                  )
                }
                onClick={() => {
                  // Prevent editing when canEditProgramSet is false
                  if (!canEditProgramSet) {
                    return
                  }

                  setModal({
                    name: 'set',
                    data: {
                      programSet: programSet,
                      isEditing: true,
                      onSave: () => {
                        publish('programSet.update', { id: programSet.id })
                      },
                    },
                  })
                }}
              />
            </Tippy>
          </div>
        </PermissionGuard>

        <div className="p-4 md:px-6">
          <div className="grid grid-cols-2-info-column gap-y-2 text-sm">
            {!isNull(programSet?.site) && (
              <>
                <div>Site</div>
                <Link to={`/site/overview/${programSet.site.id}`} className="text-primary">
                  {get(programSet, 'site.name', 'Unknown')}
                </Link>
              </>
            )}

            <div>Start Schedule / Time</div>
            <div className="text-slate-500">
              <span>{get(programSet, 'timeRange.start.date', 'Unknown')}</span>
              <span className="ml-1">/</span>
              <span className="ml-1">{get(programSet, 'timeRange.start.time', 'Unknown')}</span>
            </div>

            <div>Stop Schedule / Time</div>
            <div className="text-slate-500">
              {programSet?.timeRange?.stop
                ? (
                  <>
                    <span>{get(programSet, 'timeRange.stop.date', 'Unknown')}</span>
                    <span className="ml-1">/</span>
                    <span className="ml-1">{get(programSet, 'timeRange.stop.time', 'Unknown')}</span>
                  </>
                ) : (
                  <>
                    <span className="text-slate-400">-</span>
                  </>
                )
              }
            </div>

            <div>Irrigation Basis</div>
            <div className="text-slate-500">{capitalize(get(programSet, 'irrigationBasis', 'Unknown'))}</div>

            <div>Number of Cycles</div>
            <div className="text-slate-500">
              {programSet.infiniteCycles ? (
                <>
                  <i className="fa-solid fa-infinity mr-1"/>
                  <span>Infinite</span>
                </>
              ) : (
                <span>{programSet.numberOfCycles}</span>
              )}

              {(toNumber(programSet.numberOfCycles ?? 0) > 1 && !programSet.infiniteCycles) && (
                <>
                  <span className="ml-1">/</span>
                  <span className="ml-1">{programSet.intervalBetweenCycles}</span>
                </>
              )}

            </div>

            <div className="col-span-2 h-5"/>

            <div>Status</div>
            <PillWrapper>
              <Pill hasDot color={status?.color || '#647087'}>
                {programSet.disabled ? 'Disabled' : status?.title || 'No operational status'}
              </Pill>
            </PillWrapper>

            {isTimeBasis(programSet) && (
              <ElapsedTimerWithProgress
                statusKey={status?.key ?? 'stopped'}
                timing={get(programSet, 'timing', null)}
              />
            )}

            {isQuantityBasis(programSet) && (
              <>
                <div>Progress</div>
                <div className="grid grid-cols-2-right-auto text-slate-500">
                  <div>
                    <span className="w-30 inline-block">{formatDecimals(get(quantityProgress, 'accumulated', 0))}</span>
                    <span className="mx-1">/</span>
                    <span>{formatDecimals(get(quantityProgress, 'totalQuantity', 0))}</span>
                    <span className="w-30 inline-block">{get(quantityProgress, 'quantityUnitOfMeasurement', null)}</span>
                  </div>
                  <div>{get(quantityProgress, 'progressPercentage', 0)}%</div>
                </div>

                <div className="min-h-5"/>
                <HorizontalProgressBar
                  colorName={`status-${status?.key ?? 'stopped'}`}
                  percent={get(quantityProgress, 'progressPercentage', 0)}
                />
              </>
            )}
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide style={{ minHeight: minTabHeight }}>
        {({ isActive }) => {
          return (
            <div className="justify-content-center w-fill absolute inset-0 flex h-full bg-slate-50">
              {isActive && (
                <LeafletMap
                  id={mapId.current}
                  loading={(programSet.site.lat && programSet.site.lng) || 1000}
                  onInit={mapInit}
                  disableMarkerTooltip={true}
                  square
                  zoomControl={false}
                />
              )}
            </div>
          )
        }}
      </SwiperSlide>
    </Swiper>
  )
}
