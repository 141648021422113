import classNames from 'classnames'
import { cloneDeep, head, includes, isBoolean, map, remove } from 'lodash-es'
import { rem } from 'polished'
import { Fragment, useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useExpanded, usePagination, useTable } from 'react-table'
import styled from 'styled-components'
import useLocalStorage from 'use-local-storage'

import Dropdown from '@/Components/Dropdown'
import { Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import Select from '@/Components/form/Select'
import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'
import Tabs from '@/Components/Tabs'
import { mediaBreakpointUp } from '@/Utilities/Breakpoint'

const rowSpacing = '14px 24px'
const rowSpacingSmall = '10px 20px'

const TableWrapper = styled.div`
  background: #ffffff;
  border-radius: ${(props) => {
    return props.nested ? '0' : '5px'
  }};
  border: ${(props) => {
    return props.nested ? 'none' : '1px solid #E4E7EC'
  }};
`

const TableFiltersMedia = `
  ${mediaBreakpointUp('lg', `
    flex-wrap: nowrap;
    margin-top: 0;
    width: auto;
  `)}

  ${mediaBreakpointUp('sm', `
    & > div {
      flex-basis: auto;
    }
  `)}
`

const FilterSearchStyle = `
  .filter-search {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    z-index: 2;

    & > div {
      flex-basis: 100%;
    }

    ${TableFiltersMedia}

    .search {
      display: inline-flex;
    }
  }
`

const TableHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
  display: block;
  padding: ${rowSpacing};

  .header {
    display: inline-block;
    font-size: ${rem(18)};
    font-weight: 500;
    margin-right: 10px;
  }

  ${FilterSearchStyle}
`

const TableFilters = styled.div`
  border-bottom: 1px solid #E4E7EC;
  padding: ${rowSpacing};

  ${FilterSearchStyle}
`

const ResponsiveWrapper = styled.div`
  overflow-x: auto;
`

const StyledTable = styled.table`
  border-spacing: 0;
  min-width: ${(props) => {
    return props.tableMinWidth ? props.tableMinWidth : '750px'
  }};
  width: 100%;

  .highlight-row-mouse-indicator {
    transition: background-color 0.1s;

    &:hover {
      transition: background-color 0.1s;
      background-color: #F9FAFB;
    }
  }
`

const TableHead = styled.thead`
  background: #F9FAFB;
  border: none;
  color: #667085;
  font-size: ${rem(14)};
  text-align: left;

  th {
    border-bottom: 1px solid #E4E7EC;
    font-weight: 400;
    padding: ${rowSpacing};
  }
`

const TableBody = styled.tbody`
  border: none;
  text-align: left;

  td {
    border-top: 1px solid #E4E7EC;
    color: #667085;
    font-size: ${rem(14)};

    &.collapsible-content {
      padding: 0;
    }
  }

  ${(props) => {
    return `
      td {
        padding: ${props.compact ? rowSpacingSmall : rowSpacing}
      }
    `
  }};

  .center {
    text-align: center;
  }

  .highlight {
    background-color: #ffffff;
  }
`

const CollapsibleContent = styled.tr`
  display: none;

  &.visible {
    display: table-row;
  }
`

const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(14)};
  justify-content: space-between;
  user-select: none;
  border-top: 1px solid #E4E7EC;

  .left {
    min-width: 300px;
    text-align: left;

    .label {
      display: inline-block;
      margin-right: 15px;
    }
  }

  .right {
    align-self: center;
    text-align: right;
    justify-content: flex-end;
  }

  .left, .right {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px 20px;
  }
`

const PageNumber = styled.div`
  display: inline-block;
  margin-right: 40px;
`

const NavigationButton = styled.div`
  cursor: ${(props) => {
    return props.disabled ? 'default' : 'pointer'
  }};
  display: inline-block;
  margin: 6px 10px;
  opacity: ${(props) => {
    return props.disabled ? '0.4 !important' : 1
  }};
  padding: 2px 6px;
  transition: all 0.1s;

  &:hover {
    opacity: 0.6;
  }
`

const Filters = styled.div`
  position: relative;

  .custom-dropdown {
    left: 0;

    @media only screen and (min-width: 992px) {
      left: auto;
      right: 0;
    }
  }
`

const FilterListItem = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--white);
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  user-select: none;

  &:hover {
    background-color: var(--white);
  }

  label {
    color: var(--text-dark);
    display: flex;
    flex-grow: 1;
    font-size: ${rem(14)};
    line-height: ${rem(41)};
    margin-left: 12px;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }
  }
`

function Table(props) {
  const {
    columns,
    data,
    searchTypes,
    getTableData,
    shouldFirstLoad,
    filterList,
  } = props

  const [defaultPageSize, setDefaultPageSize] = useLocalStorage('table.pageSize', {
    value: '15',
    label: '15',
  })
  const [searchTerm, setSearchTerm] = useState({})
  const [selectedFilter, setSelectedFilter] = useState(map(filterList, 'key'))
  const [selectedTab, setSelectedTab] = useState({})
  const [firstLoad, setFirstLoad] = useState(isBoolean(shouldFirstLoad) ? shouldFirstLoad : true)

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    pageCount,
    pageOptions,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
    state: { pageIndex, pageSize }, // eslint-disable-line
  } = useTable(
    {
      columns,
      data: data.data,
      manualPagination: true,
      pageCount: data.last_page || data.lastPage,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize.value,
      },
    },
    useExpanded,
    usePagination,
  )

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)

      if (searchTypes) {
        setSearchTerm({
          ...searchTerm,
          type: head(searchTypes).value,
        })
      }
    }

    if (getTableData) {
      getTableData({
        pageIndex: pageIndex,
        pageSize: pageSize,
        filters: {
          search: searchTerm,
          tab: selectedTab,
          type: selectedFilter,
        },
      })
    }
  }, [
    getTableData,
    pageIndex,
    pageSize,
    searchTerm,
    selectedTab,
    selectedFilter,
    searchTypes,
    setSearchTerm,
  ])

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  const searchBox = (
    <div className="filter-search">
      {
        props.searchTypes &&
        <div className="search">
          <Select
            isSearchable={false}
            trailingInput
            display="inline-flex"
            width="150px"
            defaultValue={head(props.searchTypes)}
            options={props.searchTypes}
            afterChange={(option) => {
              setSearchTerm({
                ...searchTerm,
                type: option.value,
              })
            }}
          />
        </div>
      }

      <Input
        placeholder="Search"
        leadingSelect={props.searchTypes ? true : false}
        searchIcon
        value={searchTerm.value || ''}
        onChange={(event) => {
          setSearchTerm({
            ...searchTerm,
            value: event.target.value,
          })
        }}
      />
    </div>
  )

  return (
    <TableWrapper
      data-has-error={props.hasError || false}
      nested={props.nested || false}
      className="shadow"
    >
      {props?.header && (
        <TableHeader>
          <div className="grid grid-cols-2-left-auto items-center gap-4">
            <div className="w-max">
              <div className="header">
                {props.header}
              </div>

              {
                props.headerPills &&
                  <div className="hidden md:inline-block">
                    <PillWrapper>
                      {map(props.headerPills, (pill, index) => {
                        return (
                          <Pill color={pill.color} key={index}>
                            {pill.title}
                          </Pill>
                        )
                      })}
                    </PillWrapper>
                  </div>
              }
            </div>

            <div className="justify-self-end">
              <div className={classNames({ 'gap-4': props.topSearch && props.headerAction }, 'mt-2 inline-grid grid-cols-2-right-auto lg:mt-0')}>
                <div className="max-w-sm">
                  {props.topSearch ? searchBox : ''}
                </div>

                <div className="w-max">
                  {props.headerAction ? props.headerAction : ''}
                </div>
              </div>
            </div>
          </div>
        </TableHeader>
      )}

      {
        (props.filterTabs || props.filterSearch) &&
        <TableFilters>
          <div className="flex flex-col justify-between gap-3 @lg:flex-row @lg:items-center">
            <div>
              <Tabs
                tabs={props.filterTabs}
                onChange={tabChange}
              />
            </div>

            <div className="flex flex-col justify-end gap-3 @md:flex-row @md:items-center @md:justify-between">
              <div>
                {props.filterSearch ? searchBox : ''}
              </div>

              {props.filters && (
                <Filters>
                  <Dropdown
                    icon={
                      <Button className="transparent whitespace-nowrap" lineHeight="42px" onClick={() => {}}>
                        <i className="fa-solid fa-bars-filter" /> Alarm type
                      </Button>
                    }
                    noCloseOnClick
                    style={{ minWidth: '220px' }}
                    classes="custom-dropdown"
                  >
                    {filterList && (
                      map(filterList, (filterItem, key) => {
                        return (
                          <FilterListItem key={key}>
                            <input
                              type="checkbox"
                              id={filterItem.key}
                              name={filterItem.key}
                              value={filterItem.key}
                              checked={includes(selectedFilter, filterItem.key) || false}
                              onChange={(e) => {
                                if (includes(selectedFilter, e.target.value)) {
                                  let selectedAlarms = cloneDeep(selectedFilter)
                                  remove(selectedAlarms, function(item) {
                                    return item === e.target.value
                                  })

                                  setSelectedFilter(selectedAlarms)
                                } else {
                                  setSelectedFilter([...selectedFilter, e.target.value])
                                }
                              }}
                              key={filterItem.key}
                            />
                            <label htmlFor={filterItem.key}>{filterItem.title}</label><br/>
                          </FilterListItem>
                        )
                      })
                    )}
                  </Dropdown>
                </Filters>
              )}
            </div>
          </div>
        </TableFilters>
      }

      <ResponsiveWrapper>
        <StyledTable {...getTableProps()} tableMinWidth={props.tableMinWidth}>
          <TableHead>
            {map(headerGroups, (headerGroup) => {
              return (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {map(headerGroup.headers, (column, columnIndex) => {
                    return (
                      <Fragment key={columnIndex}>
                        {props.collapsibleContent && columnIndex === 0 && <th style={{ width: '50px' }}></th>}

                        <th
                          {...column.getHeaderProps()}
                          style={{ width: column.width || 'initial' }}
                        >
                          {column.render('Header')}
                        </th>
                      </Fragment>
                    )
                  })}
                </tr>
              )
            })}
          </TableHead>

          <TableBody
            {...getTableBodyProps()}
            compact={props.compact}
          >
            {
              rows.length ?
                map(rows, (row, rowIndex) => {
                  prepareRow(row)

                  return (
                    <Fragment key={rowIndex}>
                      <tr
                        {...row.getRowProps()}
                        className={classNames({ highlight: row.isExpanded }, 'highlight-row-mouse-indicator')}
                      >
                        {map(row.cells, (cell) => {
                          return (
                            <td
                              {...cell.getCellProps([{ style: cell.column.style }])}
                            >
                              {props.loading || firstLoad ? <Skeleton /> : cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>

                      {
                        (props.loading && row.isExpanded) ?
                          <tr>
                            <td colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length}>
                              <Skeleton />
                            </td>
                          </tr> :
                          <CollapsibleContent
                            className={classNames({ visible: row.isExpanded })}
                          >
                            <td
                              className="collapsible-content"
                              colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length}
                            >
                              {row.original.renderRowSubComponent}
                            </td>
                          </CollapsibleContent>
                      }
                    </Fragment>
                  )
                }) :
                (
                  <tr>
                    <td colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length} className="center">
                      {props.loading || firstLoad ? <Skeleton /> : 'No data to display.'}
                    </td>
                  </tr>
                )
            }
          </TableBody>
        </StyledTable>
      </ResponsiveWrapper>

      {!props.hidePagination && (
        <Pagination>
          <div className="left">
            <div className="label">
              Rows per page
            </div>

            <Select
              isSearchable={false}
              small
              display="inline-block"
              width="auto"
              defaultValue={[defaultPageSize]}
              options={[
                {
                  value: '5',
                  label: '5',
                },
                {
                  value: '15',
                  label: '15',
                },
                {
                  value: '25',
                  label: '25',
                },
                {
                  value: '50',
                  label: '50',
                },
                {
                  value: '100',
                  label: '100',
                },
              ]}
              afterChange={(option) => {
                if (option?.value) {
                  setDefaultPageSize(option)
                  setPageSize(option.value)
                }
              }}
            />
          </div>

          <div className="right">
            <PageNumber>
              {
                pageOptions.length !== 0 ? (
                  <>
                    Page {pageIndex + 1} of {pageOptions.length}
                  </>
                ) : (
                  <>
                    Page 1 of 1
                  </>
                )
              }
            </PageNumber>

            <NavigationButton
              onClick={() => {
                return gotoPage(0)
              }}
              disabled={!canPreviousPage}
            >
              <i className="far fa-chevron-double-left"></i>
            </NavigationButton>

            <NavigationButton
              onClick={() => {
                return previousPage()
              }}
              disabled={!canPreviousPage}
            >
              <i className="far fa-chevron-left"></i>
            </NavigationButton>

            <NavigationButton
              onClick={() => {
                return nextPage()
              }}
              disabled={!canNextPage}
            >
              <i className="far fa-chevron-right"></i>
            </NavigationButton>

            <NavigationButton
              onClick={() => {
                return gotoPage(pageCount - 1)
              }}
              disabled={!canNextPage}
            >
              <i className="far fa-chevron-double-right"></i>
            </NavigationButton>
          </div>
        </Pagination>
      )}
    </TableWrapper>
  )
}

export default Table
