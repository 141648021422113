import { has, includes, isNull, isUndefined, map, merge, omit, pickBy, startCase } from 'lodash-es'

import { formatKeys } from '@/Utilities/Form/Formatter'
import { abbreviationToText } from '@/Utilities/Units'

/**
 * An array of property names that are excluded from transformation when displayed.
 *
 * @type {string[]}
 */
const excludedFromTransformation = ['euid']

/**
 * Checks if the given value is a valid attribute value. Null and undefined values are excluded
 * when used in filtering predicate.
 *
 * @param {*} value - The value to be checked.
 * @returns {boolean} - Returns true if the value is a valid attribute value, false otherwise.
 */
const isValidAttributeValue = (value) => {
  return !isNull(value) && !isUndefined(value)
}

/**
 * Extracts attributes from the given inputOutput object.
 *
 * @param {Object} inputOutput - The inputOutput object to extract attributes from.
 * @returns {Object} - The extracted attributes.
 */
export const extractAttributes = (inputOutput) => {
  const inputOutputParsed = has(inputOutput, 'change_of_state')
    ? formatKeys(inputOutput, 'camel')
    : inputOutput
  const detailAttributes = omit(inputOutputParsed.details, ['id'])
  const externalAttributes = { EUID: inputOutputParsed.remoteUnit?.euid }
  const mergedAttributes = merge({}, detailAttributes, externalAttributes)

  return pickBy(mergedAttributes, isValidAttributeValue)
}

/**
 * Transforms and maps the given attributes to a formatted JSX element for Tippy component.
 *
 * @param {Object} attributes - The attributes to be transformed and mapped.
 * @returns {JSX.Element[]} - An array of JSX elements containing the transformed attributes.
 */
export const attributesForTippy = (attributes) => {
  return map(attributes, (value, name) => {
    // Transform the property and values except for names defined in excludedFromTransformation
    if (!includes(excludedFromTransformation, name.toLowerCase())) {
      name = startCase(name)
      value = abbreviationToText(value)
    }

    return (
      <div className="mb-1" key={name}>
        <strong>{name}:</strong> <br />
        {value}
      </div>
    )
  })
}
