import classNames from 'classnames'
import { get, isEmpty, startsWith } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import AlertList from '@/Components/alerts/AlertList'
import AppSidebar from '@/Components/layout/general/AppSidebar'
import Header from '@/Components/layout/Header'
import CookieConsent from '@/Components/modals/CookieConsent'
import Modals from '@/Components/modals/Modals'
import { isSidebarOpenState } from '@/Config/Atoms/App'
import { loginIntentState } from '@/Config/Atoms/Auth'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import useEcho from '@/Utilities/Echo'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEntityMonitor from '@/Utilities/useEntityMonitor'

export default function AppLayout() {
  useEcho()

  const apiClient = useApiClient()
  const { loggedIn } = useAuth()
  const entityMonitor = useEntityMonitor(apiClient)
  const navigate = useNavigate()
  const location = useLocation()
  const setAlert = useSetRecoilState(pageAlertState)

  const containerMain = useRef(null)

  const modal = useRecoilValue(modalState)
  const isSidebarOpen = useRecoilValue(isSidebarOpenState)
  const setLoginIntent = useSetRecoilState(loginIntentState)
  const [serviceWorkerLoaded, setServiceWorkerLoaded] = useState(false)

  // If the user is not logged in, redirect to the login page
  // and store the current location so we can redirect back
  useEffect(() => {
    if (!loggedIn) {
      setLoginIntent({ url: `${location.pathname}${location.search}` })
      navigate('/user/login', { replace: true })
    }
  }, [loggedIn])

  // When the route changes, scroll to the top of the page
  useEffect(() => {
    if (containerMain.current) {
      containerMain.current.scrollTop = 0
    }
  }, [location])

  // Listen for online/offline events and display an alert
  useEffect(() => {
    entityMonitor.fetchTrackedEntities()

    const handleOnline = () => {
      setAlert({
        type: 'success',
        content: 'You are back online!',
        clearOtherAlerts: true,
      })
    }

    const handleOffline = () => {
      setAlert({
        type: 'error',
        content: 'You appear to be offline, please check your internet connection.',
        isPermanent: true,
      })
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  const handleFocus = useCallback(async () => {
    const result = await apiClient.get('/user/profile')

    if (get(result, 'status', 0) !== 200) {
      setLoginIntent({ url: `${location.pathname}${location.search}` })
      navigate('/user/logout', { replace: true })
    } else {
      entityMonitor.fetchTrackedEntities()
    }
  }, [])

  // Determine logged in status on focus event and redirect if not logged in
  useEffect(() => {
    window.addEventListener('focus', handleFocus)

    // Cleanup
    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      if (navigator.serviceWorker.controller) {
        setServiceWorkerLoaded(true)
      }
    } else {
      setServiceWorkerLoaded(true)
    }
  }, [])

  return loggedIn ? (
    <div className="App flex h-auto min-h-screen flex-col pt-14">
      <Header />
      <AlertList />

      <div className="relative max-w-full grow bg-gray-100 pl-4 lg:flex lg:pl-0">
        <div
          className={classNames({
            'w-72': isSidebarOpen,
            'w-5': !isSidebarOpen,
          }, 'transition-all duration-300')}
        >
          <AppSidebar />
        </div>

        <div className="relative h-full-minus-top-bar grow overflow-auto pt-5 transition-all duration-300 @container @container/main" ref={containerMain}>
          {serviceWorkerLoaded ? (
            <Outlet className="main-container" />
          ) : (
            <div className="-mt-5 flex min-h-full-minus-footer-and-top-bar items-center justify-center">
              <div className="primary-loader"></div>
            </div>
          )}

          {!startsWith('/map', location.pathname) && (
            <div className="py-8 text-center text-sm text-slate-500">
              <div>
                Copyright &copy; 2022 - {moment().year()} Branif Systems. All rights reserved.
              </div>

              <div className="mt-2 flex flex-col justify-center @md:flex-row">
                <div className="mt-2 @md:m-0">
                  <a href="/terms-of-service" target="_blank">
                    Terms of Service
                  </a>
                </div>

                <div className="mx-3 hidden text-slate-300 @md:block">|</div>

                <div className="mt-2 @md:m-0">
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                </div>

                <div className="mx-3 hidden text-slate-300 @md:block">|</div>

                <div className="mt-2 @md:m-0">
                  <a href="/support" target="_blank">
                    Customer Support
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {!isEmpty(modal) && <Modals />}

      <CookieConsent />
    </div>
  ) : null
}
