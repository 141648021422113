import * as Sentry from '@sentry/react'
import { find, flatMap, get, has, includes, isEmpty, map } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'

import Card from '@/Components/common/Card'
import { Button } from '@/Components/form/Buttons'
import InputOutputCard from '@/Pages/site/overview/InputOutputCard'
import UserDefinedCard from '@/Pages/site/overview/UserDefinedCard'
import useApiClient from '@/Utilities/useApiClient'
import useEventSubscriber from '@/Utilities/useEventSubscriber'

export default function InputOutputCards(props) {
  const {
    site,
    displayedProgram,
    programActiveStatus,
    programStatusColour,
    setModal,
    setAlert,
    updateDashboard,
  } = props

  const [cardStats, setCardStats] = useState([])
  const apiClient = useApiClient()

  const { setSubscriberModelMap } = useEventSubscriber(['inputOutput'], ['stateStat'], (detail) => {
    if (includes(inputOutputIds, detail.id)) {
      const keys = [
        'flow',
        'total',
        'value',
        'changeOfState',
        'temperature',
        'moisture',
      ]

      const updateKey = find(keys, (key) => {
        return has(detail, key)
      })

      if (updateKey) {
        setCardStats((currentState) => {
          const updatedStat = {
            ...currentState[detail.id],
            [updateKey]: detail[updateKey],
          }

          return {
            ...currentState,
            [detail.id]: updatedStat,
          }
        })
      }
    }
  })

  const inputOutputIds = useMemo(() => {
    if (isEmpty(get(site, 'siteCards', []))) {
      return []
    }

    const ids = flatMap(site.siteCards, 'inputOutputId')
    setSubscriberModelMap({ inputOutput: ids })

    return ids
  }, [site])

  const getStats = useCallback(async () => {
    const inputOutputIdMap = map(inputOutputIds, (id) => {
      return ['inputOutput[]', id]
    })
    const query = new URLSearchParams(inputOutputIdMap)

    try {
      const { data } = await apiClient.get(`/state-stats?${query}`)

      if (get(data, 'input_output', false)) {
        setCardStats(data.input_output)
      }
    } catch(error) {
      Sentry.captureException(error)
    }
  }, [site])

  useEffect(() => {
    getStats()

    return () => {
      setCardStats((prevState) => {
        return { ...prevState }
      })
    }
  }, [])

  return (
    <>
      <InputOutputCard
        site={site}
        displayedProgram={displayedProgram}
        programActiveStatus={programActiveStatus}
        programStatusColour={programStatusColour}
        setModal={setModal}
        setAlert={setAlert}
        updateDashboard={updateDashboard}
        reportingStats={cardStats}
      />

      <>
        {map(site.siteCards, (card) => {
          return <UserDefinedCard
            card={card}
            site={site}
            reportingStat={get(cardStats, `${card.inputOutputId}`, {})}
            updateDashboard={updateDashboard}
            programActiveStatus={programActiveStatus}
            programStatusColour={programStatusColour}
            key={card.id}
          />
        })}

        <Card className="h-full">
          <Card.Body className="flex flex-col items-center justify-center px-3.5 py-10">
            <div>
              <i className="fa-solid fa-layer-plus fa-6x text-slate-300"></i>
            </div>

            <Button
              className="mt-5"
              style={{ width: '168px' }}
              onClick={() => {
                setModal({
                  name: 'card',
                  data: {
                    site: site,
                    isEditing: false,
                    onSave: () => {
                      updateDashboard()
                    },
                  },
                })
              }}
            >
            Add card
            </Button>
          </Card.Body>
        </Card>
      </>
    </>
  )
}
