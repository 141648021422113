import { get } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useRef } from 'react'

export default function useProgramTimerFallback({
  identifyingKey,
  identifyingId,
  displayed,
  setSecondsRemaining,
  status = '',
}) {
  let intervalId = useRef(null)

  const clearTimer = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
      intervalId.current = null
    }
  }, [])

  const calculateRuntime = useCallback((options) => {
    const timing = options.displayed.timing

    if (!timing) {
      return false
    }

    const elapsedTimeSinceLastUpdate = timing.elapsedSeconds
    const remainingRuntime = timing.totalSeconds - elapsedTimeSinceLastUpdate

    return remainingRuntime
  }, [])

  const createTimer = useCallback((options) => {
    let remainingRuntimeTimer = calculateRuntime(options)

    if (remainingRuntimeTimer) {
      intervalId.current = setInterval(() => {
        // Ensure the interval does not run infinitely
        if (remainingRuntimeTimer <= 0) {
          clearTimer()
          return
        }

        remainingRuntimeTimer -= 1
        options.set(remainingRuntimeTimer)
      }, 1000)
    }

    return false
  }, [])

  useEffect(() => {
    return () => {
      clearTimer()
    }
  }, [])

  useEffect(() => {
    if (status !== 'running' && status !== 'paused') {
      const fullRuntime = moment.duration(displayed.runtime).asSeconds()
      setSecondsRemaining(fullRuntime)
    }

    if (status !== 'running') {
      clearTimer()
    }

    if (status === 'paused') {
      const remainingRuntimeTimer = calculateRuntime({
        identifyingKey: identifyingKey,
        identifyingId: identifyingId,
        displayed: displayed,
        set: setSecondsRemaining,
      })

      setSecondsRemaining(remainingRuntimeTimer)
    }

    if (status === 'running' && get(displayed, 'timing.shouldTick', false) && !intervalId.current) {
      createTimer({
        identifyingKey: identifyingKey,
        identifyingId: identifyingId,
        displayed: displayed,
        set: setSecondsRemaining,
      })
    }
  }, [
    identifyingKey,
    identifyingId,
    displayed,
    setSecondsRemaining,
    status,
    intervalId,
  ])
}
