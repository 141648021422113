import * as yup from 'yup'

import { transformToNumber } from '@/Utilities/Validation/Transforms/ToNumber'

const mainLineSchema = yup.object().shape({
  siteId: yup.mixed()
    .label('Site')
    .populatedObject()
    .meta({ tabKey: 'general' }),

  name: yup.string()
    .label('Name')
    .required()
    .min(3)
    .max(50)
    .meta({ tabKey: 'general' }),

  description: yup.string()
    .label('Description')
    .max(250)
    .nullable()
    .meta({ tabKey: 'general' }),

  flowMeterType: yup.mixed()
    .label('Flow meter type')
    .notRequired(),

  flowMeterId: yup.mixed()
    .label('Flow meter')
    .when('flowMeterType', ([flowMeterType], schema) => {
      if (flowMeterType?.value) {
        return schema.populatedObject()
      }

      return schema.notRequired()
    })
    .meta({ tabKey: 'general' }),

  uncontrolledDetection: yup.number()
    .label('Uncontrolled detection')
    .nullable()
    .when('flowMeterType', ([flowMeterType], schema) => {
      if (flowMeterType?.value) {
        return schema.required().min(1).max(60)
      }

      return schema.notRequired()
    })
    .transform(transformToNumber)
    .meta({ tabKey: 'general' }),

  inputOutputIds: yup.mixed()
    .label('I/Os').populatedObject()
    .meta({ tabKey: 'io' }),

  suspendFlowControlWhenFlushing: yup.boolean()
    .label('Suspend flow control')
    .nullable()
    .meta({ tabKey: 'flowControl' }),

  maximumSuspendTimeMinutes: yup.number()
    .label('Maximum suspend time minutes')
    .when('suspendFlowControlWhenFlushing', ([suspendFlowControlWhenFlushing], schema) => {
      if (suspendFlowControlWhenFlushing) {
        return schema.required().min(0).max(200)
      }

      return schema.notRequired()
    })
    .transform(transformToNumber)
    .meta({ tabKey: 'flowControl' }),

  maximumSuspendTimeSeconds: yup.number()
    .label('Maximum suspend time seconds')
    .when('suspendFlowControlWhenFlushing', ([suspendFlowControlWhenFlushing], schema) => {
      if (suspendFlowControlWhenFlushing) {
        return schema.required().min(0).max(200)
      }

      return schema.notRequired()
    })
    .transform(transformToNumber)
    .meta({ tabKey: 'flowControl' }),

  maximumSuspendCycles: yup.number()
    .label('Maximum suspend cycles')
    .when('suspendFlowControlWhenFlushing', ([suspendFlowControlWhenFlushing], schema) => {
      if (suspendFlowControlWhenFlushing) {
        return schema.required().min(1).max(200)
      }

      return schema.notRequired()
    })
    .transform(transformToNumber)
    .meta({ tabKey: 'flowControl' }),

  dpSwitch: yup.boolean()
    .label('DP switch')
    .nullable()
    .meta({ tabKey: 'flowControl' }),

  dpSwitchInputId: yup.mixed()
    .label('DP switch input')
    .when('dpSwitch', ([dpSwitch], schema) => {
      if (dpSwitch) {
        return schema.populatedObject()
      }

      return schema.notRequired()
    })
    .meta({ tabKey: 'flowControl' }),

  topPressure: yup.boolean()
    .label('Top pressure')
    .nullable()
    .meta({ tabKey: 'flowControl' }),

  topPressureInputId: yup.mixed()
    .label('Top pressure input')
    .when('topPressure', ([topPressure], schema) => {
      if (topPressure) {
        return schema.populatedObject()
      }

      return schema.notRequired()
    })
    .meta({ tabKey: 'flowControl' }),

  topPressureValue: yup.number()
    .label('Top pressure value')
    .when('topPressure', ([topPressure], schema) => {
      if (topPressure) {
        return schema.required().min(1).max(200)
      }

      return schema.notRequired()
    })
    .transform(transformToNumber)
    .meta({ tabKey: 'flowControl' }),

  bottomPressure: yup.boolean()
    .label('Bottom pressure')
    .required()
    .meta({ tabKey: 'flowControl' }),

  bottomPressureInputId: yup.mixed()
    .label('Bottom pressure input')
    .when('bottomPressure', ([bottomPressure], schema) => {
      if (bottomPressure) {
        return schema.populatedObject()
      }

      return schema.notRequired()
    })
    .meta({ tabKey: 'flowControl' }),

  bottomPressureValue: yup.number()
    .label('Bottom pressure value')
    .when('bottomPressure', ([bottomPressure], schema) => {
      if (bottomPressure) {
        return schema.required().min(1).max(200)
      }

      return schema.notRequired()
    })
    .transform(transformToNumber)
    .meta({ tabKey: 'flowControl' }),
})

export default mainLineSchema
