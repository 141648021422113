import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { debounce, isEmpty, map, truncate } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import ActionIcon from '@/Components/ActionIcon'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import CircleList from '@/Components/CircleList'
import SearchInput from '@/Components/common/input/SearchInput'
import TableHeader from '@/Components/common/table/Header'
import DropdownList from '@/Components/DropdownList'
import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'
import SkeletonTable from '@/Components/SkeletonTable'
import TableBasic from '@/Components/tables/base/Basic'
import { modalState } from '@/Config/Atoms/General'
import { standardActions } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { getPaginationMeta } from '@/Utilities/Pagination'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'

function AreasAll() {
  // Utilities
  const navigate = useNavigate()
  const auth = useAuth()
  const apiClient = useApiClient()

  // Internal component state
  const [tableData, setTableData] = useState(null)
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [tableSearchTerm, setTableSearchTerm] = useState({})
  const [lastQuery, setLastQuery] = useState()
  const setModal = useSetRecoilState(modalState)

  // Events
  useEventSubscriber(['area'], standardActions, () => {
    updateTable()
  })

  const updateTable = useCallback(() => {
    getTableData(getPaginationMeta(lastQuery))
  }, [lastQuery])

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      const currentQuery = {
        pageIndex,
        pageSize,
        filters,
      }

      setLastQuery(currentQuery)

      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['search', filters?.search?.value || ''],
        ['with[]', 'sites'],
      ])

      try {
        const { data } = await apiClient.get(`/area/query?${query}`)

        let areaData = map(data.areas.data, (area) => {
          return {
            name: (
              <div className="flex items-center">
                <div>
                  <Tippy content={`${area.active ? 'Successfully' : 'Not yet'} activated with the FEP`} theme="light" placement="right">
                    <span className="mr-4 p-1">
                      <span className={classNames({
                        'bg-green-600': area.active,
                        'bg-yellow-500': !area.active,
                      }, 'w-3 h-3 inline-block rounded-full')}></span>
                    </span>
                  </Tippy>
                </div>

                <div>
                  <div>
                    {area.name}
                  </div>
                  {area.description ? (
                    <Tippy content={area.description} delay={200} theme="light" disabled={area.description.length < 50} placement="right">
                      <div className="inline-block text-xs text-slate-400">
                        {truncate(area.description, { length: 50 })}
                      </div>
                    </Tippy>
                  ) : null}
                </div>
              </div>
            ),
            address: area.address,
            authorised: !isEmpty(area.users) ? (
              <CircleList
                tooltips
                compact
                circles={map(area.users, (user) => {
                  return {
                    text: user.name,
                    avatar: user.avatar || null,
                  }
                })}
              />
            ) : (
              <span>
              All
              </span>
            ),
            sites: (
              area.sites &&
            <PillWrapper>
              {map(area.sites, (site, index) => {
                return (
                  <Pill color="#344054" key={index}>
                    {site.name}
                  </Pill>
                )
              })}
            </PillWrapper>
            ),
            action: (
              <PermissionGuard anyOf={[
                'view-area',
                'update-area',
                'create-site',
              ]} auth={auth}>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[
                    {
                      label: 'Edit area',
                      onClick: () => {
                        setModal({
                          name: 'area',
                          data: {
                            ...formatKeys(area, 'camel'),
                            isEditing: true,
                            onSave: () => {
                              getTableData(currentQuery)
                            },
                          },
                        })
                      },
                      disabled: !auth.can('update-area'),
                    },
                    {
                      label: 'Manage area',
                      onClick: () => {
                        navigate(`/area/manage/${area.id}`)
                      },
                      disabled: !auth.can('view-area'),
                    },
                    {
                      label: 'Add site',
                      topLine: true,
                      onClick: () => {
                        setModal({
                          name: 'site',
                          data: { site: { areaId: area.id } },
                        })
                      },
                      disabled: !auth.can('create-site'),
                    },
                  ]}
                />
              </PermissionGuard>
            ),
          }
        })

        let tableData = {
          ...data.areas,
          data: areaData,
        }

        setTableData(tableData)
        setTableDataLoading(false)
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 250)
  }, [
    setTableData,
    setTableDataLoading,
    navigate,
    setModal,
  ])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: 'Name',
          accessor: 'name',
          width: '25%',
        },
        {
          Header: 'Sites',
          accessor: 'sites',
          width: '35%',
        },
        {
          Header: 'Authorized users',
          accessor: 'authorised',
          width: '20%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
          style: { textAlign: 'right' },
        },
      ]
    }, [getTableData],
  )

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(getPaginationMeta(params))
  }, [getTableData, setTableDataLoading])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [getTableData])

  return (
    <>
      {
        tableData ?
          <TableBasic
            testId="tableAreasAll"
            columns={tableColumns}
            data={tableData}
            getTableData={getTableDataStart}
            header={
              <TableHeader
                testId="tableAreasTableHeader"
                className="grid-cols-2-left-auto"
              >
                <TableHeader.Title>Areas</TableHeader.Title>

                <TableHeader.Pills className="justify-self-end md:ml-2.5 md:justify-self-start">
                  <PillWrapper>
                    <Pill color="#175CD3">{tableData.total} areas</Pill>
                  </PillWrapper>
                </TableHeader.Pills>

                <TableHeader.Toolbar className="hidden md:block">
                  <SearchInput
                    className="block w-full flex-wrap"
                    term={tableSearchTerm}
                    onTermUpdate={(value) => {
                      return setTableSearchTerm({
                        ...tableSearchTerm,
                        value,
                      })
                    }
                    }
                  />
                </TableHeader.Toolbar>

                <TableHeader.SubHeader className="flex flex-col justify-between gap-3 @lg:flex-row @lg:items-center md:hidden">
                  <SearchInput
                    className="flex w-full flex-wrap"
                    term={tableSearchTerm}
                    onTermUpdate={(value) => {
                      return setTableSearchTerm({
                        ...tableSearchTerm,
                        value,
                      })
                    }
                    }
                  />
                </TableHeader.SubHeader>
              </TableHeader>
            }
            loading={tableDataLoading}
            topSearch
            searchTerm={tableSearchTerm}
          /> : <SkeletonTable />
      }
    </>
  )
}

export default AreasAll
